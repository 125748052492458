/* noto-sans-tc-100normal - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Noto Sans TC Thin '),
    local('Noto Sans TC-Thin'),
    url('./files/noto-sans-tc-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-tc-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-tc-300normal - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Noto Sans TC Light '),
    local('Noto Sans TC-Light'),
    url('./files/noto-sans-tc-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-tc-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-tc-400normal - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Noto Sans TC Regular '),
    local('Noto Sans TC-Regular'),
    url('./files/noto-sans-tc-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-tc-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-tc-500normal - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Noto Sans TC Medium '),
    local('Noto Sans TC-Medium'),
    url('./files/noto-sans-tc-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-tc-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-tc-700normal - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Noto Sans TC Bold '),
    local('Noto Sans TC-Bold'),
    url('./files/noto-sans-tc-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-tc-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-tc-900normal - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Noto Sans TC Black '),
    local('Noto Sans TC-Black'),
    url('./files/noto-sans-tc-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-tc-latin-900.woff') format('woff'); /* Modern Browsers */
}


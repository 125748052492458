@import '~@angular/material/theming';
// Plus imports for other components in your app.
@import '~typeface-noto-sans/index.css';
@import '~typeface-noto-sans-tc/index.css';
@import '~material-icons/iconfont/material-icons.css';



// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

//Custom primary color
$portal-custom-primary: (
    50 : #e5e7e9,
    100 : #bec3c8,
    200 : #939ba3,
    300 : #67737e,
    400 : #475562,
    500 : #263746,
    600 : #22313f,
    700 : #1c2a37,
    800 : #17232f,
    900 : #0d1620,
    A100 : #61a5ff,
    A200 : #2e87ff,
    A400 : #006bfa,
    A700 : #0060e0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

//Custom secondary color
$portal-custom-secondary: (
    50 : #e0f3fb,
    100 : #b3e1f5,
    200 : #80ceef,
    300 : #4dbae8,
    400 : #26abe3,
    500 : #009cde,
    600 : #0094da,
    700 : #008ad5,
    800 : #0080d1,
    900 : #006ec8,
    A100 : #f1f8ff,
    A200 : #bedeff,
    A400 : #8bc4ff,
    A700 : #71b7ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);





// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portal-app-primary: mat-palette($portal-custom-primary);
$portal-app-accent:  mat-palette($portal-custom-secondary);

// The warn palette is optional (defaults to red).
$portal-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$portal-app-theme: mat-light-theme($portal-app-primary, $portal-app-accent, $portal-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($portal-app-theme);
